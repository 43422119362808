import React from "react";
import { Link } from "react-router-dom";
import logo from "images/emilogo.png";


export interface LogoProps {
  img?: string;
  className?: string;
}

const Logo: React.FC<LogoProps> = ({
  img = logo,
  className = "w-30",
}) => {
  return (
    <Link
      to="https://emiwriter.com/"
      className={`ttnc-logo inline-block text-primary-6000 focus:outline-none focus:ring-0 ${className}`}
    >

        <img
          className={`block max-h-20 `}
          src={img}
          alt="Logo"
        />
      
    </Link>
  );
};

export default Logo;
