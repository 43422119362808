import React, { FC } from "react";
import Logo from "shared/Logo/Logo";


export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = () => {
  return (
    <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
       <div className="mx-auto flex items-center">
          <Logo />
      </div>
    </div>
  );
};

export default MainNav1;
