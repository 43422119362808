import { FC, useEffect, useState } from 'react';
import NcImage from "shared/NcImage/NcImage";

// export interface BlogType {
//   id: string | number;
//   name: string;
//   href: string;
//   thumbnail?: string;
// }

// export interface SectionVideosProps {
//   bogPost?: BlogType[];
//   className?: string;
// }

interface PostData {
  id: number;
  link: string;
  title: string ;
  featured_media: number;
  url: string ;
}

// const BLOG_INFO: BlogType[] = [
//   {
//     id: "1",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
//   {
//     id: "2",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
//   {
//     id: "3",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
//   {
//     id: "4",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
//   {
//     id: "5",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
//   {
//     id: "6",
//     href: "https://emiwriter.com/a-whole-new-life/",
//     name: "From a beach getaway to a whole new life",
//     thumbnail:
//       "https://emiwriter.com/wp-content/uploads/2019/03/blog1-scaled-e1684588613760.jpg",
//   },
// ];


const PageHome = ({
  // bogPost = BLOG_INFO,
}) => {
  const [currentPost, setCurrentPost] = useState(0);
  const [apiData, setApiData] = useState<PostData[]>([]);

  useEffect(() => {
    // Make the API call here
    const fetchData = async () => {
      try {
        const response = await fetch('https://emiwriter.com/wp-json/wp/v2/posts');
        const data = await response.json();
        //console.log(data);
        const posts = await Promise.all(data.map(async (post: any) => {
          const featuredMediaResponse = await fetch(`https://emiwriter.com/wp-json/wp/v2/media/${post.featured_media}`);
          const featuredMediaData = await featuredMediaResponse.json();
          const featuredMediaUrl = featuredMediaData?.guid.rendered ?? '';
          // console.log(featuredMediaUrl);
          return {
            id: post.id,
            link: post.link,
            title: post.title.rendered,
            url: featuredMediaUrl,
          };
        }));
        posts.sort((a, b) => b.id - a.id)
        setApiData(posts);
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, []);



  const renderPost = (post: PostData, index: number) => {
    return (
      <a
      href={post.link}
      target="_blank"
      rel="noopener noreferrer"
      key={String(post.id)}
       >
      <div
        className="group relative aspect-[3/3]  cursor-pointer overflow-hidden  will-change-transform"
        onClick={() => {
          setCurrentPost(index);
        }}
        key={String(post.id)}
      >
       
        <NcImage
          containerClassName="absolute inset-0 w-full h-full"
          className="object-cover w-full h-full transform transition-transform group-hover:scale-110 duration-300 nc-will-change-transform"
          src={post.url}
          title={post.title}
          alt={post.title}
        />
      </div>
      </a>
    );
  };

  return (
    <div className="container nc-PageHome   ">
      
      {/* <div className="flex flex-col"> */}
      <div className="items-center justify-center py-10 w-full">
          <a href="https://emiwriter.com/" target="_blank" rel="noopener noreferrer">
            <span className="block px-3 py-2 border border-black text-base font-medium text-black text-center">
              Visit Website
            </span>
          </a>
        </div> 
          <div className="flex flex-row ">    
          <div className="container relative ">
               <div className=" grid gap-2 grid-cols-3">
              {apiData.map(renderPost)}
               </div>
            </div>
         </div>
      {/* </div> */}
    </div>
  );
};

export default PageHome;
